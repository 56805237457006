import React from 'react';
import JotihuntAssignments from './JotihuntAssignments';
import { Toaster } from 'react-hot-toast';

const App: React.FC = () => {
  return (
    <div className="App">
      <JotihuntAssignments />
      <div><Toaster position="top-right" /></div>
    </div>
  );
};

export default App;