import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Typography, Alert, Button, Tag, Modal, Radio, message, Input, Popover, List, Card, Collapse, Checkbox, Select, Layout } from 'antd';
import { CloseCircleOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import toast, { Toaster } from 'react-hot-toast';
import { DateTime, Duration } from 'luxon';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off, update, set, get } from 'firebase/database';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';

const { Title } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;

// Firebase configuration (unchanged)
const firebaseConfig = {
    apiKey: "AIzaSyALAW6Lu1DIiRAEzYM296UbHss6-TMjip4",
    authDomain: "joti-ac8a9.firebaseapp.com",
    databaseURL: "https://joti-ac8a9-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "joti-ac8a9",
    storageBucket: "joti-ac8a9.appspot.com",
    messagingSenderId: "251330001797",
    appId: "1:251330001797:web:756761545862eeffc522ec"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

type AssignmentStatus = 'not_started' | 'in_progress' | 'completed';

interface Assignment {
    key: number;
    id: number;
    title: string;
    end_time: string;
    status: AssignmentStatus;
    message: {
        content: string;
    };
    remark: string;
    timeLeft?: number;
}

interface ApiResponse {
    data: Array<{
        id: number;
        title: string;
        type: string;
        message: {
            end_time: string;
            content: string;
        };
    }>;
}

const AMSTERDAM_TIMEZONE = 'Europe/Amsterdam';

const StatusPill: React.FC<{ status: AssignmentStatus }> = ({ status }) => {
    let color = 'default';
    let text = 'Onbekend';

    switch (status) {
        case 'not_started':
            color = 'error';
            text = 'Niet begonnen';
            break;
        case 'in_progress':
            color = 'warning';
            text = 'Mee bezig';
            break;
        case 'completed':
            color = 'success';
            text = 'Gedaan';
            break;
    }

    return <Tag color={color}>{text}</Tag>;
};

const JotihuntAssignments: React.FC = () => {
    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState<Assignment | null>(null);
    const [newStatus, setNewStatus] = useState<AssignmentStatus>('not_started');
    const [newRemark, setNewRemark] = useState('');
    const [, updateState] = useState<{}>();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const isDesktop = useMediaQuery('(min-width:768px)');
    const [filteredAssignments, setFilteredAssignments] = useState<Assignment[]>([]);
    const [statusFilter, setStatusFilter] = useState<AssignmentStatus[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [timeFilter, setTimeFilter] = useState<string | null>(null);
    const [siderCollapsed, setSiderCollapsed] = useState(false);

    const handleTableChange = (pagination: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const applyFilters = useCallback(() => {
        let filtered = assignments;

        // Apply status filter
        if (statusFilter.length > 0) {
            filtered = filtered.filter(assignment => statusFilter.includes(assignment.status));
        }

        // Apply search term filter
        if (searchTerm) {
            const lowercasedTerm = searchTerm.toLowerCase();
            filtered = filtered.filter(assignment =>
                assignment.title.toLowerCase().includes(lowercasedTerm) ||
                assignment.remark.toLowerCase().includes(lowercasedTerm)
            );
        }


        // Apply time filter
        if (timeFilter) {
            const now = DateTime.now().setZone(AMSTERDAM_TIMEZONE);
            filtered = filtered.filter(assignment => {
                const endTime = DateTime.fromISO(assignment.end_time, { zone: AMSTERDAM_TIMEZONE });
                const diff = endTime.diff(now, 'hours').hours;
                switch (timeFilter) {
                    case '1h': return diff <= 1;
                    case '3h': return diff <= 3;
                    case '6h': return diff <= 6;
                    case '12h': return diff <= 12;
                    case '24h': return diff <= 24;
                    default: return true;
                }
            });
        }

        setFilteredAssignments(filtered);
    }, [assignments, statusFilter, searchTerm, timeFilter]);

    useEffect(() => {
        applyFilters();
    }, [applyFilters, assignments]);

    const renderFilters = () => (
        <Sider
            width={250}
            theme="light"
            // collapsible
            // collapsed={siderCollapsed}
            // onCollapse={setSiderCollapsed}
            style={{ background: '#fff', padding: '20px' }}
        >
            <Title level={4}>Filters</Title>
            <Collapse defaultActiveKey={['1', '2', '3']}>
                <Panel header="Status" key="1">
                    <Checkbox.Group
                        options={[
                            { label: 'Niet begonnen', value: 'not_started' },
                            { label: 'Mee bezig', value: 'in_progress' },
                            { label: 'Gedaan', value: 'completed' }
                        ]}
                        value={statusFilter}
                        onChange={(values) => setStatusFilter(values as AssignmentStatus[])}
                    />
                </Panel>
                <Panel header="Zoeken" key="2">
                    <Input
                        placeholder="Zoek op titel of opmerking"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Panel>
                <Panel header="Tijd over" key="3">
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecteer tijdsperiode"
                        value={timeFilter}
                        onChange={setTimeFilter}
                    >
                        <Option value={null}>Alle</Option>
                        <Option value="1h">≤ 1 uur</Option>
                        <Option value="3h">≤ 3 uur</Option>
                        <Option value="6h">≤ 6 uur</Option>
                        <Option value="12h">≤ 12 uur</Option>
                        <Option value="24h">≤ 24 uur</Option>
                    </Select>
                </Panel>
            </Collapse>
        </Sider>
    );

    const formatTimeLeft = (endTime: string): string => {
        const amsterdamEndTime = DateTime.fromISO(endTime, { zone: AMSTERDAM_TIMEZONE });
        const amsterdamNow = DateTime.now().setZone(AMSTERDAM_TIMEZONE);
        const difference = amsterdamEndTime.diff(amsterdamNow);

        if (difference.as('seconds') <= 0) {
            return 'Verlopen';
        }

        const duration = Duration.fromObject(difference.toObject()).shiftTo('days', 'hours', 'minutes', 'seconds');
        const { days, hours, minutes, seconds } = duration.toObject();

        const parts = [];
        if (days && days > 0) parts.push(`${Math.floor(days)} ${Math.floor(days) === 1 ? 'dag' : 'dagen'}`);
        if (hours && hours > 0) parts.push(`${Math.floor(hours)} ${Math.floor(hours) === 1 ? 'uur' : 'uur'}`);
        if (minutes && minutes > 0) parts.push(`${Math.floor(minutes)} ${Math.floor(minutes) === 1 ? 'minuut' : 'minuten'}`);
        if (seconds && seconds > 0) parts.push(`${Math.floor(seconds)} ${Math.floor(seconds) === 1 ? 'seconde' : 'seconden'}`);

        if (parts.length === 0) {
            return 'Minder dan een minuut';
        }

        if (parts.length === 1) {
            return parts[0];
        }

        if (parts.length === 2) {
            return `${parts[0]} en ${parts[1]}`;
        }

        return `${parts[0]}, ${parts[1]} en ${parts[2]}`;
    };

    const fetchJotihuntAssignments = async () => {
        try {
            const response = await axios.get<ApiResponse>('https://jotihunt.nl/api/2.0/articles');
            const jotihuntAssignments = response.data.data
                .filter(item => item.type === 'assignment')
                .map(item => ({
                    key: item.id,
                    id: item.id,
                    title: item.title,
                    end_time: item.message.end_time,
                    status: 'not_started' as AssignmentStatus,
                    message: {
                        content: item.message.content
                    },
                    remark: ''
                }));

            const assignmentsRef = ref(database, 'assignments');
            const snapshot = await get(assignmentsRef);
            const existingAssignments = snapshot.val() || {};

            jotihuntAssignments.forEach(async (assignment) => {
                if (!existingAssignments[assignment.id]) {
                    await set(ref(database, `assignments/${assignment.id}`), assignment);
                }
            });

        } catch (err) {
            console.error('Error fetching Jotihunt assignments:', err);
            setError('Failed to fetch Jotihunt assignments');
        }
    };

    useEffect(() => {
        const assignmentsRef = ref(database, 'assignments');

        const handleData = (snapshot: any) => {
            const data = snapshot.val();
            if (data) {
                const assignmentsList = Object.values(data) as Assignment[];
                setAssignments(assignmentsList);
                setLoading(false);
            } else {
                setAssignments([]);
                setLoading(false);
            }
        };

        onValue(assignmentsRef, handleData, (error) => {
            console.error('Error fetching assignments:', error);
            setError('Failed to fetch assignments');
            setLoading(false);
        });

        fetchJotihuntAssignments();
        const intervalId = setInterval(fetchJotihuntAssignments, 5 * 60 * 1000); // Fetch every 5 minutes

        // Cleanup listeners on component unmount
        return () => {
            off(assignmentsRef);
            clearInterval(intervalId);
        };
    }, []);

    const calculateTimeLeft = (endTime: string) => {
        const amsterdamEndTime = DateTime.fromISO(endTime, { zone: AMSTERDAM_TIMEZONE });
        const amsterdamNow = DateTime.now().setZone(AMSTERDAM_TIMEZONE);
        const difference = amsterdamEndTime.diff(amsterdamNow, ['days', 'hours', 'minutes', 'seconds', 'milliseconds']);
        if (difference.milliseconds > 0) {
            return {
                days: Math.floor(difference.days),
                hours: Math.floor(difference.hours),
                minutes: Math.floor(difference.minutes),
                seconds: Math.floor(difference.seconds),
                totalMilliseconds: difference.milliseconds
            };
        }
        return null;
    };

    const updateAssignmentStatus = async (id: number, newStatus: AssignmentStatus, newRemark: string) => {
        try {
            const updates: { [key: string]: string | AssignmentStatus } = {};
            updates[`/assignments/${id}/status`] = newStatus;
            updates[`/assignments/${id}/remark`] = newRemark;
            await update(ref(database), updates);
            message.success('Status en opmerking succesvol bijgewerkt');
        } catch (error) {
            console.error('Error updating assignment status and remark:', error);
            message.error('Fout bij het bijwerken van de status en opmerking');
        }
    };

    const openStatusModal = (e: React.MouseEvent, assignment: Assignment) => {
        e.stopPropagation();  // Stop event propagation
        setSelectedAssignment(assignment);
        setNewStatus(assignment.status);
        setNewRemark(assignment.remark || '');
        setModalVisible(true);
    };

    const handleStatusChange = () => {
        if (selectedAssignment) {
            updateAssignmentStatus(selectedAssignment.id, newStatus, newRemark);
            setModalVisible(false);
            setSelectedAssignment(null);
        }
    };

    const onRowClick = (record: Assignment) => {
        setExpandedRowKeys(expandedRowKeys[0] === record.key ? [] : [record.key]);
    };

    const renderMobileItem = (item: Assignment) => {
        const timeLeft = calculateTimeLeft(item.end_time);
        const timeLeftText = timeLeft
            ? `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
            : 'Verlopen';

        return (
            <List.Item>
                <Card
                    title={item.title}
                    extra={<StatusPill status={item.status} />}
                    style={{ width: '100%' }}
                >
                    <p><strong>Deadline:</strong> {DateTime.fromISO(item.end_time, { zone: AMSTERDAM_TIMEZONE }).toFormat('dd-MM-yyyy HH:mm:ss')}</p>
                    <p><strong>Tijd over:</strong> {formatTimeLeft(item.end_time)}</p>
                    <p><strong>Opmerking:</strong> {item.remark || 'Geen opmerking'}</p>
                    <Collapse>
                        <Panel header="Toon opdrachtomschrijving" key="1">
                            <div
                                style={{ maxHeight: '400px', overflow: 'auto' }}
                                dangerouslySetInnerHTML={{ __html: item.message.content }}
                            />
                        </Panel>
                    </Collapse>
                    <Button
                        icon={<EditOutlined />}
                        onClick={(e) => openStatusModal(e, item)}
                        type="primary"
                        style={{ marginTop: '10px' }}
                    >
                        Bewerk Status
                    </Button>
                </Card>
            </List.Item>
        );
    };

    const showCustomToast = (message: string) => {
        toast((t) => (
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <span>{message}</span>
                <Button
                    type="text"
                    icon={<CloseCircleOutlined />}
                    onClick={() => toast.dismiss(t.id)}
                    style={{ padding: '4px', height: 'auto', color: 'white' }}
                >
                    Sluiten
                </Button>
            </span>
        ), {
            duration: 10000000,
            style: {
                background: '#ff0000',
                color: 'white',
                padding: '12px 20px',
                borderRadius: '4px',
            },
        });
    };

    useEffect(() => {
        const timer = setInterval(() => {
            forceUpdate();
            assignments.forEach(assignment => {
                if (assignment.status !== 'completed') {
                    const timeLeft = calculateTimeLeft(assignment.end_time);
                    if (timeLeft) {
                        const { totalMilliseconds } = timeLeft;
                        assignment.timeLeft = totalMilliseconds;
                        if (totalMilliseconds <= 3600000 && totalMilliseconds > 3599000) {
                            showCustomToast(`1 uur over voor: ${assignment.title}`);
                        } else if (totalMilliseconds <= 1800000 && totalMilliseconds > 1799000) {
                            showCustomToast(`30 minuten over voor: ${assignment.title}`);
                        } else if (totalMilliseconds <= 900000 && totalMilliseconds > 899000) {
                            showCustomToast(`15 minuten over voor: ${assignment.title}`);
                        }
                    } else {
                        assignment.timeLeft = -1; // Indicates expired
                    }
                } else {
                    assignment.timeLeft = Infinity; // Completed tasks go to the bottom when sorting by time left
                }
            });
            setAssignments([...assignments]); // Trigger re-render
        }, 1000);
        return () => clearInterval(timer);
    }, [assignments, forceUpdate]);

    const columns = [
        {
            title: 'Opdracht',
            dataIndex: 'title',
            key: 'title',
            width: '25%',
            sorter: (a: Assignment, b: Assignment) => a.title.localeCompare(b.title),
        },
        {
            title: 'Deadline',
            dataIndex: 'end_time',
            key: 'end_time',
            width: '20%',
            render: (end_time: string) => {
                const amsterdamTime = DateTime.fromISO(end_time, { zone: AMSTERDAM_TIMEZONE });
                return amsterdamTime.toFormat('dd-MM-yyyy HH:mm:ss');
            },
            sorter: (a: Assignment, b: Assignment) => DateTime.fromISO(a.end_time).toMillis() - DateTime.fromISO(b.end_time).toMillis(),
        },
        {
            title: 'Tijd over',
            key: 'timeLeft',
            render: (record: Assignment) => formatTimeLeft(record.end_time),
            sorter: (a: Assignment, b: Assignment) => {
                const timeLeftA = calculateTimeLeft(a.end_time)?.totalMilliseconds || 0;
                const timeLeftB = calculateTimeLeft(b.end_time)?.totalMilliseconds || 0;
                return timeLeftA - timeLeftB;
            },
        },
        {
            title: 'Status',
            key: 'status',
            width: '15%',
            render: (record: Assignment) => (
                <StatusPill status={record.status} />
            ),
            sorter: (a: Assignment, b: Assignment) => a.status.localeCompare(b.status),
        },
        {
            title: 'Opmerking',
            dataIndex: 'remark',
            key: 'remark',
            width: '15%',
            ellipsis: true,
            render: (text: string) => {
                if (text && text.length > 20) {
                    return (
                        <Popover content={text} title="Volledige opmerking" trigger="hover">
                            <span>{text.slice(0, 20)}...</span>
                        </Popover>
                    );
                }
                return text;
            },
        },
        {
            title: '',
            key: 'actions',
            width: '10%',
            render: (record: Assignment) => (
                <Button
                    icon={<EditOutlined />}
                    onClick={(e) => openStatusModal(e, record)}
                    type="text"
                />
            ),
        },
    ];

    if (error) return <Alert message="Error" description={error} type="error" showIcon />;

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {isDesktop && renderFilters()}
            <Content style={{ padding: isDesktop ? '24px' : '16px', maxWidth: isDesktop ? '1200px' : '100%', margin: '0 auto' }}>
                <Toaster />
                <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Jotihunt opdrachten
                </Title>
                {!isDesktop && (
                    <Button
                        icon={<FilterOutlined />}
                        onClick={() => setSiderCollapsed(!siderCollapsed)}
                        style={{ marginBottom: '20px' }}
                    >
                        {siderCollapsed ? 'Toon filters' : 'Verberg filters'}
                    </Button>
                )}
                {!isDesktop && !siderCollapsed && renderFilters()}
                {isDesktop ? (
                    <Table
                        dataSource={filteredAssignments}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: filteredAssignments.length,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: (total, range) => `${range[0]}-${range[1]} van ${total} opdrachten`,
                        }}
                        onChange={handleTableChange}
                        expandable={{
                            expandedRowRender: record => (
                                <div
                                    style={{ maxHeight: '400px', overflow: 'auto' }}
                                    dangerouslySetInnerHTML={{ __html: record.message.content }}
                                />
                            ),
                            expandedRowKeys,
                        }}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                    />
                ) : (
                    <List
                        dataSource={filteredAssignments}
                        renderItem={renderMobileItem}
                        pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: filteredAssignments.length,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: (total, range) => `${range[0]}-${range[1]} van ${total} opdrachten`,
                            onChange: (page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            },
                        }}
                        loading={loading}
                    />
                )}
            </Content>
            <Modal
                title="Status en opmerking wijzigen"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={handleStatusChange}
            >
                {selectedAssignment && (
                    <div>
                        <p>Opdracht: {selectedAssignment.title}</p>
                        <Radio.Group
                            onChange={(e) => setNewStatus(e.target.value)}
                            value={newStatus}
                            style={{ marginBottom: '16px' }}
                        >
                            <Radio.Button value="not_started">Niet begonnen</Radio.Button>
                            <Radio.Button value="in_progress">Mee bezig</Radio.Button>
                            <Radio.Button value="completed">Gedaan</Radio.Button>
                        </Radio.Group>
                        <TextArea
                            rows={4}
                            value={newRemark}
                            onChange={(e) => setNewRemark(e.target.value)}
                            placeholder="Voeg een opmerking toe (bijv. wie er mee bezig is)"
                        />
                    </div>
                )}
            </Modal>
        </Layout>

    );
};

export default JotihuntAssignments;